<template>
  <el-card class="mb20"
    shadow="never">
    <div slot="header"
      class="search_box">
      <div class="left">
        筛选条件
      </div>
      <div class="right">
        <el-button type="text"
          @click="phone = !phone">
          {{ phone?'展开':'收起' }}
          <i
            class="ml10 el-icon-arrow-up"></i>
        </el-button>
      </div>
    </div>

    <!-- 搜索开始 -->
    <el-form v-if="!phone" :inline="true" @submit.native.prevent
      ref="form" :model="form"
      label-width="70px">


          <el-form-item
            style="margin-bottom: 0;"
            label="手机号">
            <el-input
              v-model="form.creatorMobile"
              style="width: 100%;"
              placeholder="请输入" />
          </el-form-item>



          <el-form-item
            style="margin-bottom: 0;"
            label="应用名">
            <el-input
              v-model="form.name"
              style="width: 100%;"
              placeholder="应用名" />
          </el-form-item>


          <el-form-item
            style="margin-bottom: 0;"
            label="状态">
            <el-select
              v-model="form.state"
              style="width:100%"
              @change="stateChange"
              placeholder="请选择">
                <el-option
                  label="全部"
                  :value="''" />
                <el-option
                  label="申请中"
                  :value="1" />
                <el-option
                  label="已取消"
                  :value="2" />
                <el-option
                  label="已上架"
                  :value="3" />
                <el-option
                  label="已拒绝"
                  :value="4" />
                <el-option
                  label="已下架"
                  :value="5" />
                <el-option
                  label="已过时"
                  :value="6" />
            </el-select>
          </el-form-item>

          <!-- 搜索 按钮 -->
          <el-form-item label="" class="btnWrap">
            <el-button class="btnWrap" native-type="submit" type="primary" @click="searchBtn">搜索</el-button>
            <el-button class="btnWrap" @click="repert">重置</el-button>
          </el-form-item>

    </el-form>
  </el-card>
  <!-- 表单结束 -->
</template>

<script>
// 引入组件及方法
export default {
  name: 'Yxb30uiSearch',
  props: ['columns'],
  // 数据
  data() {
    return {
      phone: false,
      showMore: true, // 查看更多
      form: {
        state: '',
        creatorMobile: '',
        name:""
      } // form对象
    }
  },

  mounted() {
    this.phone = JSON.parse(window.localStorage.getItem('phone'))
  },
  methods: {
    // 点击搜索
    searchBtn() {
      this.$emit('search', this.form)
    },
    // 重置
    repert() {
      this.form.state = ''
      this.form.name = ''
      this.form.creatorMobile = ''

      this.$emit('search', this.form)
    },
    stateChange(){
      this.$emit('search', this.form)

    }
  }
}
</script>

<style lang="scss" scoped>
.search_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-form-item{
  padding: 8px 0;
}
.btnWrap{
  // margin-left: 37px;
}
</style>
