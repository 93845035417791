<template>
    <div>

        <search @search="searchClick"></search>
        

        <el-card >


            <el-table
                :data="tableData"
                style="width: 100%"
                @row-click="showDetail"
                border>


                <el-table-column
                    prop="name"
                    label="应用名称"
                    width="140">
                    <!-- <template slot-scope="scope">
                        <el-button @click.native.prevent="showDetail(scope.row)" type="text" size="small">
                            {{ scope.row.name }}
                        </el-button>
                    </template> -->
                    
                </el-table-column>

                <el-table-column
                    prop="creatorMobile"
                    label="手机号"
                    width="130">
                </el-table-column>
                


                <el-table-column
                    prop="appId"
                    label="appId"
                    width="130">
                </el-table-column>


                <el-table-column
                    prop="state"
                    label="状态"
                    width="130">
                    <template slot-scope="scope">
                        <span v-if="scope.row.state == 1">申请中</span>
                        <span v-if="scope.row.state == 2">已取消</span>
                        <span v-if="scope.row.state == 3">已通过/已上架</span>
                        <span v-if="scope.row.state == 4">已拒绝</span>
                        <span v-if="scope.row.state == 5">已下架</span>
                        <span v-if="scope.row.state == 6">已过时</span>
                    </template>
                </el-table-column>

                <!-- <el-table-column
                    prop="appVersionId"
                    label="appVersionId">
                </el-table-column> -->
                

                

                <el-table-column
                    prop="cid1"
                    label="分类"
                    width="130">

                    <template slot-scope="scope">
                        <el-button @click.stop="changeCategory(scope.row)" type="text" size="small">
                            {{scope.row.cname1?scope.row.cname1:'未分类'}}
                        </el-button>
                    </template>


                </el-table-column>

                <el-table-column
                    prop="sort"
                    label="排序值">
                </el-table-column>

            





                <el-table-column
                    fixed="right"
                    label="操作"
                    width="220">
                    <template slot-scope="scope">
                        <el-button
                            @click.stop="showDetail(scope.row)"
                            type="text"
                            size="small">
                            应用详情
                        </el-button>

                        <el-button v-if="scope.row.state == 1"
                            @click.stop="changeRow(scope.row)"
                            type="text"
                            size="small">
                            去审核
                        </el-button>
                        <el-button v-if="scope.row.state == 3"
                            @click.stop="OffShelf(scope.row)"
                            type="text"
                            size="small">
                            下架
                        </el-button>
                        <el-button v-if="scope.row.state == 5"
                            @click.stop="upShelf(scope.row)"
                            type="text"
                            size="small">
                            上架
                        </el-button>
                        <el-button
                            @click.stop="setSort(scope.row)"
                            type="text"
                            size="small">
                            设置排序值
                        </el-button>
                        <!-- <el-button
                            @click.stop="appSet(scope.row)"
                            type="text"
                            size="small">
                            应用设置
                        </el-button> -->
                        <el-button
                            @click.stop="appSet2(scope.row)"
                            type="text"
                            size="small">
                            应用设置
                        </el-button>
                        

                    </template>
                </el-table-column>
            
            </el-table>

            <!-- <el-pagination
                background
                :page-size="pageSize"
                :current-page="pageNumber"
                layout="prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :total="total">
            </el-pagination> -->


            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNumber"
                :page-sizes="[20, 50, 100, 150]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>


        </el-card>


        <el-dialog
            title="审核"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">
                
                <div class="dialogWrap">


                    <el-form ref="form" :model="form" label-width="80px">


                        


                        <el-form-item label="是否通过" prop="examinationState">
                            <el-radio-group v-model="form.examinationState">
                                <el-radio :label="3">通过</el-radio>
                                <el-radio :label="4">拒绝</el-radio>
                            </el-radio-group>
                        </el-form-item>


                        <el-form-item label="审核意见" prop="memo" :rules="[{ required: true, message: '审核意见不能为空', trigger: 'blur' }]">
                            <el-input type="textarea" :rows="3" placeholder="请输入审核意见" v-model="form.memo">

                            </el-input>                        
                        </el-form-item>

                    </el-form>



                    

                    

                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="sureChangeClick()">确 定</el-button>
                </span>
        </el-dialog>

        <appDetail ref="appDetailRef" :visible.sync="appDetailVisible"></appDetail>
        <setSortDialog ref="setSortDialogRef" @close="sortCloseHandle" :visible.sync="setSortDialogVisible"></setSortDialog>
        <ChangeCategory ref="ChangeCategoryRef" @close="ChangeCategoryCloseHandle" :visible.sync="ChangeCategoryDialogVisible"></ChangeCategory>

        <appSetDialog ref="appSetDialogRef" :visible.sync = "appSetDialogVisible"></appSetDialog>
        <appSetDialog2 ref="appSetDialogRef2" :visible.sync = "appSetDialogVisible2"></appSetDialog2>


    </div>
</template>
  
  
<script>
  // 引入组件及方法

  import appDetail from "./appDetail.vue"
  import setSortDialog from "./setSortDialog.vue"
  import search from "./search.vue"
  import ChangeCategory from "./ChangeCategory.vue"

  import appSetDialog from "./appSetDialog.vue"
  import appSetDialog2 from "./appSetDialog2.vue"

  export default {
    components: { appDetail , setSortDialog, search, ChangeCategory, appSetDialog, appSetDialog2},
    data() {
      return {
        tableData:[],
        total:0,
        pageNumber:1,
        pageSize:20,
        dialogVisible:false,

        curEditItem:null,

        form:{
            examinationState:3,
            memo:""
        },

        queryParams:{},

        appDetailVisible:false,
        setSortDialogVisible:false,

        categoryList:[],
        ChangeCategoryDialogVisible:false,
        appSetDialogVisible:false,

        appSetDialogVisible2:false

        
      };
    },
    async mounted() {


        // var param = {
        //     pageNumber:1,
        //     pageSize:30,
        //     pid:0
        // }
        // var res = await REQUEST.POST("queryCategory", param)

        // this.categoryList = res.data.records



      this.getData()



    },
    methods: {
        // 获取数据
        getData() {
            
            this.$https('GET_queryApps', {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                ...this.queryParams
            }).then(res => {
                this.tableData = res.data.records
                this.total = parseInt(res.data.total)
            })
        },
        changeRow(item){
            
            this.curEditItem = item
            this.examinationState = 3
            this.memo = ''
            this.dialogVisible = true
        },
        OffShelf(item) {

            // this.$alert('确定要下架吗', '标题名称', {
            // confirmButtonText: '确定',
            // callback: async action => {
                
            //     try{
            //         await this.$https.get('SET_AppVersionOffline', {appVersionId: item.appVersionId})
            //         this.$message({
            //             message: '下架成功',
            //             type: 'success'
            //         });

            //     }catch(e){

            //     }

            //     this.getData()

            // }
            // });



            this.$confirm('确定要下架吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                try {
                    await this.$https.get('SET_AppVersionOffline', {appVersionId: item.appVersionId})
                    this.$message({
                        message: '下架成功',
                        type: 'success'
                    })
                } catch (e) {

                }
                this.getData()
            }).catch(() => {
                
            })
        },

        //上架
        upShelf(item){
            // this.$alert('确定要上架吗', '标题名称', {
            // confirmButtonText: '确定',
            // callback: async action => {
                
            //     try{
            //         await this.$https.get('SET_AppVersionOnline', {appVersionId: item.appVersionId})
            //         this.$message({
            //             message: '上架成功',
            //             type: 'success'
            //         });

            //     }catch(e){

            //     }

            //     this.getData()

            // }
            // });




            this.$confirm('确定要上架吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                try{
                    await this.$https.get('SET_AppVersionOnline', {appVersionId: item.appVersionId})
                    this.$message({
                        message: '上架成功',
                        type: 'success'
                    });

                }catch(e){

                }

                this.getData()
            }).catch(() => {
                
            })
        },

        handleClose() {
            this.dialogVisible = false

        },
        async sureChangeClick() {


            this.$refs["form"].validate(async (valid) => {
                console.log("valid", valid)
                if (valid) {
                    
                    var res = await this.$https('GET_auditApp', {
                        state: this.form.examinationState,
                        appVersionId: this.curEditItem.appVersionId,
                        memo:this.form.memo
                    })

                    this.dialogVisible = false
                    this.getData()

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });





        },
        handleSizeChange(size) {
            this.pageSize = size
            this.getData()
        },
        handleCurrentChange(num) {
            this.pageNumber = num
            this.getData()
        },

        showDetail(item){
            this.appDetailVisible = true
            this.$nextTick(() => {
                this.$refs.appDetailRef.init(item)
            })

        },
        setSort(item){
            this.setSortDialogVisible = true
            this.$nextTick(() => {
                this.$refs.setSortDialogRef.init(item)
            })
        },
        sortCloseHandle(){
            this.getData()

        },
        searchClick(data){
            this.pageNumber = 1
            this.queryParams = data
            this.getData()
        },
        openDetails(row){
            console.log(row)

        },
        changeCategory(row){

            this.ChangeCategoryDialogVisible = true
            this.$nextTick(()=>{
                this.$refs.ChangeCategoryRef.init(row)
            })

        },
        ChangeCategoryCloseHandle(){
            this.getData()
        },
        appSet(row){
            this.appSetDialogVisible = true
            this.$nextTick(()=>{
                this.$refs.appSetDialogRef.init(row.appId, row.appVersionId)
            })
        },
        appSet2(row){
            this.appSetDialogVisible2 = true
            this.$nextTick(()=>{
                this.$refs.appSetDialogRef2.init(row.appId, row.appVersionId)
            })
        }
    },
  };
</script>
  
<style scoped >
.dialogWrap{
    padding: 20px 0 20px 80px;
    box-sizing: border-box;
    
}
.radioWrap{
    padding: 10px 0;
}
</style>
  