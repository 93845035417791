<template>
    <el-dialog :visible.sync="visible" 
        :before-close="handleClose"  
        center
        width="80%"
        :close-on-click-modal="false"
        title="应用设置"
        append-to-body>

        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">

            <el-form-item label="扩展功能：" prop="extensionsList">
                <el-checkbox-group v-model="ruleForm.extensionsList">
                    <el-checkbox v-for="(item, index) in extensionsCheckbox" :label="item.label" :key="index">{{ item.name }}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>

            <!-- <el-form-item label="自动播放语音" prop="optionsList">
                <el-checkbox-group v-model="ruleForm.optionsList">
                    <el-checkbox v-for="(item, index) in optionsCheckbox" :label="item.label" :key="index">{{ item.name }}</el-checkbox>
                </el-checkbox-group>
            </el-form-item> -->

            <el-form-item label="支持的模型类型" prop="appModelTypesList">
                <el-checkbox-group v-model="ruleForm.appModelTypesList">
                    <el-checkbox v-for="(item, index) in appModelTypesCheckbox" :label="item.label" :key="index">{{ item.name }}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>

        </el-form>

        <span slot="footer" class="dialog-footer">
            
            <el-button type="success" @click="sureClick">确定</el-button>
        </span>

    </el-dialog>
  </template>
  
  <script>
  export default {
    data() {
      return {
        num:0,
        curItem:null,
        appId:null,
        appVersionId:null,

        extensionsCheckbox:[
            {
                label:1,
                name:"语音"
            },{
                label:2,
                name:"解释图片(gpt4才能用)"
            },{
                label:4,
                name:"画图"
            }
        ],
        optionsCheckbox:[
            {
                label:1,
                name:"自动播放语音"
            }
        ],
        appModelTypesCheckbox:[
            {
                label:1,
                name:"GPT3.5"
            },{
                label:8,
                name:"GPT4"
            }
        ],
        ruleForm:{
            extensionsList:[],
            optionsList:[],
            appModelTypesList:[]
        },
        rules:{}

      }
    },
    props:{
        visible:Boolean,
    },
    
    components:{},
    mounted() {

    },
    watch: {
        visible(){
            
        }
    },
    computed:{

    },
  
    methods: {
        handleClose(){
            if(this.loading){
                return
            }
            this.$emit('update:visible', false)
        },

        async init(appId, appVersionId){
            this.appId = appId
            this.appVersionId = appVersionId

            var res = await this.$https.get('GET_App', {appVersionId:this.appVersionId})

            this.ruleForm.extensionsList = []
            
            this.extensionsCheckbox.forEach((val)=>{
                var n = res.data.extensions & val.label
                if(n > 0){
                    this.ruleForm.extensionsList.push(val.label)
                }
            })

            // this.ruleForm.optionsList = []
            // this.optionsCheckbox.forEach((val)=>{
            //     var n = res.data.options & val.label
            //     if(n > 0){
            //         this.ruleForm.optionsList.push(val.label)
            //     }
            // })

            this.ruleForm.appModelTypesList = []
            this.appModelTypesCheckbox.forEach((val)=>{
                var n = res.data.availableModelTypes & val.label
                if(n > 0){
                    this.ruleForm.appModelTypesList.push(val.label)
                }
            })
        },

        async sureClick(){
            var param = {
                appId:this.appId,
                options:0,
                extensions:0
            }

            if(this.ruleForm.extensionsList && this.ruleForm.extensionsList.length > 0){
                var extensions = 0
                this.ruleForm.extensionsList.forEach((val)=>{
                    extensions = extensions | val
                })
                param.extensions = extensions
            }

            // if(this.ruleForm.optionsList && this.ruleForm.optionsList.length > 0){
            //     var options = 0
            //     this.ruleForm.optionsList.forEach((val)=>{
            //         options = options | val
            //     })
            //     param.options = options
            // }

            if(this.ruleForm.appModelTypesList && this.ruleForm.appModelTypesList.length > 0){
                var n = 0
                this.ruleForm.appModelTypesList.forEach((val)=>{
                    n = n | val
                })
                param.appModelTypes = n
            }

            var res = await this.$https("updateApp", param)
            this.$message({
                message:"修改成功",
                type: 'success',
                duration: 2000
            })

            this.handleClose()

        }
     
    }
  }
  </script>
  <style lang="scss" scoped>


  </style>