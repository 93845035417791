<template>
    <el-dialog :visible.sync="visible" 
        :before-close="handleClose"  
        center
        width="80%"
        :close-on-click-modal="false"
        title="应用设置"
        append-to-body>

        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">

            <el-form-item label="扩展功能：" prop="extensionsList">
                <el-checkbox-group v-model="ruleForm.extensionsList">
                    <el-checkbox v-for="(item, index) in extensionsCheckbox" :label="item.label" :key="index">{{ item.name }}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>

            <!-- <el-form-item label="自动播放语音" prop="optionsList">
                <el-checkbox-group v-model="ruleForm.optionsList">
                    <el-checkbox v-for="(item, index) in optionsCheckbox" :label="item.label" :key="index">{{ item.name }}</el-checkbox>
                </el-checkbox-group>
            </el-form-item> -->
            

        </el-form>



        <span slot="footer" class="dialog-footer">
            
            <el-button type="success" @click="sureClick">确定</el-button>
        </span>

    </el-dialog>
  </template>
  
  <script>
  export default {
    data() {
      return {
        num:0,
        curItem:null,
        appId:null,
        appVersionId:null,
        optionsCheckbox:[
            {
                label:1,
                name:"自动播放语音"
            }
        ],

        // 1 GPT 3.5, 8 GPT 4.0, 2 向量化, 4 OpenAI文本转语音, 16 OpenAI语音转文本, 32 OpenAI画图, 64 OpenAI图片分析
        extensionsCheckbox:[
            // {
            //     label:1,
            //     name:"GPT3.5"
            // },{
            //     label:8,
            //     name:"GPT4.0"
            // },{
            //     label:2,
            //     name:"向量化"
            // },{
            //     label:4,
            //     name:"OpenAI文本转语音"
            // },{
            //     label:16,
            //     name:"OpenAI语音转文本"
            // },{
            //     label:32,
            //     name:"OpenAI画图"
            // },{
            //     label:64,
            //     name:"OpenAI图片分析"
            // }
        ],
        ruleForm:{
            extensionsList:[],
            optionsList:[],
        },
        rules:{}

      }
    },
    props:{
        visible:Boolean,
    },
    
    components:{},
    mounted() {
      
    },
    watch: {
        visible(){
            
        }
    },
    computed:{

    },
  
    methods: {
        handleClose(){
            if(this.loading){
                return
            }
            this.$emit('update:visible', false)
        },

        async init(appId, appVersionId){
            this.appId = appId
            this.appVersionId = appVersionId

            var res = await this.$https.get('GET_App', {appVersionId:this.appVersionId})

            this.ruleForm.extensionsList = []

            await this.loadAllModel()
            
            this.extensionsCheckbox.forEach((val)=>{
                var n = res.data.availableModelValue & val.label
                if(n > 0){
                    this.ruleForm.extensionsList.push(val.label)
                }
            })

            // this.ruleForm.optionsList = []
            // this.optionsCheckbox.forEach((val)=>{
            //     var n = res.data.options & val.label
            //     if(n > 0){
            //         this.ruleForm.optionsList.push(val.label)
            //     }
            // })

        },

        async loadAllModel(){
            
            var res = await this.$https('getAppModels', {})
            // console.log("所有模型",  res);
            this.extensionsCheckbox = []
            res.data.forEach((val)=>{
                this.extensionsCheckbox.push({
                    label: parseInt(val.modelValue),
                    name: val.name
                })
            })

        },

        async sureClick(){
            var param = {
                appId:this.appId,
                availableModelValue:0
            }

            var extensions = 0
            if(this.ruleForm.extensionsList && this.ruleForm.extensionsList.length > 0){
                
                this.ruleForm.extensionsList.forEach((val)=>{
                    extensions = extensions | val
                })
            }
            param.availableModelValue = extensions


            // var options = 0
            // if(this.ruleForm.optionsList && this.ruleForm.optionsList.length > 0){
                
            //     this.ruleForm.optionsList.forEach((val)=>{
            //         options = options | val
            //     })
            // }
            // param.options = options

            var res = await this.$https("updateApp", param)
            this.$message({
                message:"修改成功",
                type: 'success',
                duration: 2000
            })

            this.handleClose()

        }
     
    }
  }
  </script>
  <style lang="scss" scoped>


  </style>