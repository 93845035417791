<template>
    <div>

        <el-select v-model="innerSel" placeholder="请选择" v-loadMore="loadMore">
            <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
        </el-select>

    </div>
  </template>
  <script>

  export default {
    props:['selValue'],
    data() {
        return {
            pageNumber:0,
            pageSize:20,
            pid:0,

            options:[],
            isLast:false
        }
    },
    computed:{
        innerSel:{
            get(){
                return this.selValue
            },
            set(newVal){
                this.$emit("update:selValue", newVal)
            }
        }
    },
    async mounted(){

        this.loadMore()
        
    },
    methods: {
        async loadMore(){

            if(this.isLast){
                return
            }

            this.pageNumber++
            var param = {
                pageNumber:this.pageNumber,
                pageSize:this.pageSize,
                pid:this.pid
            }

            var res = await this.$https("queryCategory", param)
            // var res = await REQUEST.POST("queryCategory", param)


            res.data.records.forEach((val)=>{
                this.options.push(val)
            })

            if(res.data.records.length == 0){
                this.isLast = true
            }

        }
    }
  }
  </script>
  <style scoped>


  </style>
  