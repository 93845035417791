<template>
    <el-dialog :visible.sync="visible" 
        :before-close="handleClose"  
        center
        width="80%"
        :close-on-click-modal="false"
        title="应用详情"
        append-to-body>

        <div class="wrap" v-if="form">

            <div class="textWrap">
                <div class="tit">appId:</div>
                <div class="content">{{ form.appId }}</div>
            </div>
            <div class="textWrap">
                <div class="tit">名字:</div>
                <div class="content">{{ form.name }}</div>
            </div>

            <div class="textWrap">
                <div class="tit">AI指令:</div>
                <div class="content">{{ form.params.command }}</div>
            </div>

            <div class="textWrap">
                <div class="tit">参数:</div>
                <div class="content">{{ paramsFilter(form.params.params) }}</div>
            </div>
            
            <div class="textWrap">
                <div class="tit">第一句话:</div>
                <div class="content">{{ form.params.firstPrompt }}</div>
            </div>
            <div class="textWrap">
                <div class="tit">应用简介:</div>
                <div class="content">{{ form.summary }}</div>
            </div>
            <div class="textWrap">
                <div class="tit">每日免费次数:</div>
                <div class="content">{{ form.freeCountDaily }}</div>
            </div>
            <div class="textWrap">
                <div class="tit">每次多少工钱:</div>
                <div class="content">{{ form.coinPerTime }}</div>
            </div>
            <div class="textWrap">
                <div class="tit">是否是系统定义应用:</div>
                <div class="content">{{ form.isSystem==1 ? "是": "否" }}</div>
            </div>

            <div class="textWrap">
                <div class="tit">可见范围:</div>
                <div class="content">{{ scopeFilter( form.scope) }}</div>
            </div>
            
            
        </div>



        <span slot="footer" class="dialog-footer">
            
            <div class="closeBtn" @click="handleClose">关闭</div>
        </span>

    </el-dialog>
  </template>
  
  <script>
  export default {
    data() {
      return {
        form:null

      }
    },
    props:{
        visible:Boolean,
    },
    
    components:{},
    mounted() {

      
    },
    watch: {
        visible(){
            
        }
    },
    computed:{

        

        

    },
  
    methods: {
        handleClose(){
            if(this.loading){
                return
            }
            this.$emit('update:visible', false)
        },

        async init(item){


            var res = await this.$https.get('GET_App', {appVersionId: item.appVersionId})
            this.form = res.data
            console.log("this.form",item)

        },
        scopeFilter(s){
            // 1 仅自己可见，2 所有人可见，3 组织内可见
            if(s == 1){
                return "仅自己可见"
            }else if(s == 2){
                return "所有人可见"
            }else if(s == 3){
                return "组织内可见"
            }

        },
        paramsFilter(list){
            var arr = list.map((item)=>{
                return "{{" + item.name + "}}"
            })
            return arr.join("，")
        }
    }
  }
  </script>
  <style lang="scss" scoped>

    .closeBtn{
        width: 69px;
        height: 39px;
        line-height: 39px;
        text-align: center;
        color: rgb(83, 94, 254);
        font-size: 14px;
        border: 1px solid rgb(83, 94, 254);
        border-radius: 6px;    
        margin-right:20px;
        cursor: pointer;
    }
    .dialog-footer{
        padding-top: 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .textWrap{
        display: flex;
        flex-direction: row;
        font-size: 16px;
        color: rgb(122, 122, 122);
        padding: 6px 20px;
        .tit{
            text-align: right;
            width: 190px;
            padding-right: 16px;
            line-height: 27px;
            color: rgb(163, 163, 163);

        }
        .content{
            flex: 1;
            line-height: 27px;
            color: rgb(66, 66, 66);

        }
    }
  </style>