<template>
    <el-dialog :visible.sync="visible" 
        :before-close="handleClose"  
        center
        width="500px"
        :close-on-click-modal="false"
        title="设置分类"
        append-to-body>

        <div class="wrap"  >

            <CategorySeleteInput class="categoryWrap" :selValue.sync="item.cid1"></CategorySeleteInput>
            
        </div>



        <span slot="footer" class="dialog-footer">
            
            <div class="closeBtn" @click="handleClose">  取消</div>
            <el-button type="success" @click="sureClick">确定</el-button>
        </span>

    </el-dialog>
  </template>
  
  <script>
  import CategorySeleteInput from './CategorySeleteInput.vue'
  export default {
    data() {
      return {
        item:{
            cid1:'0'
        }

      }
    },
    props:{
        visible:Boolean,
    },
    
    components:{CategorySeleteInput},
    mounted() {

      
    },
    watch: {
        visible(){
            
        }
    },
    computed:{

        

        

    },
  
    methods: {
        async init(item){

            this.item = item

        },

        handleClose(){
            if(this.loading){
                return
            }
            this.$emit('update:visible', false)
        },



        async sureClick(){
            var res = await this.$https('updateAppCategory', {appId: this.item.appId, cid1:this.item.cid1})
            this.$emit('update:visible', false)
            this.$emit('close')

        }

     
    }
  }
  </script>
  <style lang="scss" scoped>
    .wrap{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 60px 0;
    }
    .closeBtn{
        width: 69px;
        height: 39px;
        line-height: 39px;
        text-align: center;
        color: rgb(83, 94, 254);
        font-size: 14px;
        border: 1px solid rgb(83, 94, 254);
        border-radius: 6px;    
        margin-right:20px;
        cursor: pointer;
    }
    .dialog-footer{
        padding-top: 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 40px 0;
    }

  </style>