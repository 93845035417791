<template>
    <el-dialog :visible.sync="visible" 
        :before-close="handleClose"  
        center
        width="80%"
        :close-on-click-modal="false"
        title="设置排序值"
        append-to-body>

        <div class="wrap"  >

            <el-input-number v-model="num"  :max="10000000" label="排序值"></el-input-number>
            
            
        </div>



        <span slot="footer" class="dialog-footer">
            
            <div class="closeBtn" @click="handleClose">关闭</div>
            <el-button type="success" @click="sureClick">确定</el-button>
        </span>

    </el-dialog>
  </template>
  
  <script>
  export default {
    data() {
      return {
        num:0,
        curItem:null

      }
    },
    props:{
        visible:Boolean,
    },
    
    components:{},
    mounted() {

      
    },
    watch: {
        visible(){
            
        }
    },
    computed:{

        

        

    },
  
    methods: {
        handleClose(){
            if(this.loading){
                return
            }
            this.$emit('update:visible', false)
        },

        async init(item){

            this.num = item.sort

            this.curItem = item


        },

        async sureClick(){
            var res = await this.$https('UPDATE_AppSort', {id: this.curItem.appId, sort:this.num})
            this.$emit('update:visible', false)
            this.$emit('close')

        }

     
    }
  }
  </script>
  <style lang="scss" scoped>

    .wrap{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .closeBtn{
        width: 69px;
        height: 39px;
        line-height: 39px;
        text-align: center;
        color: rgb(83, 94, 254);
        font-size: 14px;
        border: 1px solid rgb(83, 94, 254);
        border-radius: 6px;    
        margin-right:20px;
        cursor: pointer;
    }
    .dialog-footer{
        padding-top: 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .textWrap{
        display: flex;
        flex-direction: row;
        font-size: 16px;
        color: rgb(122, 122, 122);
        padding: 6px 20px;
        .tit{
            text-align: right;
            width: 190px;
            padding-right: 16px;
            line-height: 27px;
            color: rgb(163, 163, 163);

        }
        .content{
            flex: 1;
            line-height: 27px;
            color: rgb(66, 66, 66);

        }
    }
  </style>